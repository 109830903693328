import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TosterService } from '../services/toster.service';
import { StorageService } from 'src/app/core/services/storage.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private tosterService: TosterService, public router: Router, private route: ActivatedRoute, private storageService: StorageService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (Math.floor(err.status / 100) === 4) {
                //client err handling           
                this.tosterService.presentToast(err.error.message, 'Error', 'error');
            }
            if (Math.floor(err.status / 100) === 5) {
                //server err handling
                this.tosterService.presentToast(err.error.message, 'Error', 'error');
            }

            console.log(err);
            return throwError(err);
        }))
    }
}
