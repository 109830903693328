import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { AppDataService } from './app-data.service';
import { BehaviorSubject } from 'rxjs';
import { TosterService } from './toster.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiService: ApiRequestService, private toasterService: TosterService) { }

  onlyNumberAllow(event,type): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (type == 'OTP') {
      if (event.target.value.length == 4) {
        return false;
      }
    }
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      return false;
    }
    return true;
  }
}
