import { Injectable } from '@angular/core';
import { AppDataService } from './app-data.service';
import { from } from 'rxjs';

export type URL_KEYS ='getRestaurantDetails'|'sendOtp' |'verifyOtp'|'sendConsentResponse';

const UrlMapping = {
  getRestaurantDetails: { url: AppDataService.baseUrl + '/support/restConsent/getRestDetails', showMsg: true },
  sendOtp: {url:AppDataService.baseUrl+'/support/restConsent/sendOtp',showMsg:true},
  verifyOtp: {url:AppDataService.baseUrl+'/support/restConsent/verifyOtp',showMsg:true},
  sendConsentResponse: {url:AppDataService.baseUrl+'/support/restConsent/sendConsent',showMsg:true},


}
export class RequestUrlService {
  static get(key: URL_KEYS): any {
		return UrlMapping[key];
	}
}
