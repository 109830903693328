import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantDetailsService {

  constructor(private apiService: ApiRequestService) { }

  getRestaurantDetails(restId) {
    return this.apiService.get('getRestaurantDetails', '?restId=' + restId );
  }

  sendOtpDetails(restObj){
    return this.apiService.post('sendOtp',restObj);
  }

  verifyOtpDetails(restObj){
    return this.apiService.post('verifyOtp',restObj);
  }

  sendConsentResponse(restObj){
    return this.apiService.post('sendConsentResponse',restObj);
  }
}
