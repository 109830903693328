import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestaurantDetailsComponent } from './module/restaurant-details/restaurant-details.component';
import { ThankyouComponent } from './shared/component/thankyou/thankyou.component';


const routes: Routes = [
   {
     path:'',
     component:RestaurantDetailsComponent
   },
   {
     path:'thankyou',
     component:ThankyouComponent
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
