import { Component, OnInit } from '@angular/core';
import { RestaurantDetailsService } from './service/restaurant-details.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { StorageService } from 'src/app/core/services/storage.service';
import { TosterService } from 'src/app/shared/services/toster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-restaurant-details',
  templateUrl: './restaurant-details.component.html',
  styleUrls: ['./restaurant-details.component.scss']
})
export class RestaurantDetailsComponent implements OnInit {
  // subscriptionForm:FormGroup;
  otp = new FormControl('', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]);
  responseData;
  outletsInfo;
  response;
  mobileNum;
  showOtp = false;
  restId;
  spinnerShow = false;
  emailId;
  planSelected;
  selectedPlan;
  subscriptionData;
  outletKeys = [
    { 'name': 'outletName', 'displayName': 'Outlet Name' },
    { 'name': 'locality', 'displayName': 'Locality' },
    { 'name': 'fssai', 'displayName': 'FSSAI' },
    { 'name': 'gstin', 'displayName': 'GSTIN' },
    { 'name': 'accNum', 'displayName': 'Account Number' },
    { 'name': 'bankName', 'displayName': 'Bank Name' }
  ]
  planKeys=[
    { 'name': 'planName', 'displayName': 'Plan Name' },
    { 'name': 'amount', 'displayName': 'Amount' },
    { 'name': 'description', 'displayName': 'Description' },
  ]
  constructor(private restaurantService: RestaurantDetailsService,
    public commonService: CommonService, private storageService: StorageService,
    private toasterService: TosterService, private route: ActivatedRoute, 
    public router: Router, private fb: FormBuilder,private modalService: NgbModal) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.restId = params['restId'];
      console.log(this.restId);
    });

    this.getRestInfo()
  }

  getRestInfo() {
    let restId = this.restId;
    if (this.restId !== undefined) {
      this.spinnerShow = true;
      this.restaurantService.getRestaurantDetails(restId).then((data) => {
        console.log("dataObj", data);
        this.responseData = data['body']['data']['restDetails'];
        this.outletsInfo = this.responseData['outlets'];
        this.subscriptionData =  this.responseData.subscription;
        if (data['body']['type'] === 'success') {
          this.spinnerShow = false;
          this.planSelected =this.responseData['subscription'][0].planName;
        }
      }).catch((err) => {
        console.log(err);
        this.spinnerShow = false;
      });
    }
  }

  onItemChange(item) {
    console.log(item);
  }
  agreeInfo(content) {
    this.modalService.open(content, {size:'sm',backdrop: 'static',centered:true })
    let obj = {
      'restId': this.restId
    };
    this.restaurantService.sendOtpDetails(obj).then((data) => {
      console.log("dataObj", data);
      this.mobileNum = data['body']['data']['mobile'];
      this.emailId = data['body']['data']['email'];
      this.storageService.setItem('mobileNum', this.mobileNum);
      this.storageService.setItem('emailId', this.emailId);

    }).catch((err) => {
      console.log(err);
    });
  }

  disAgreeInfo() {
    let obj = {
      'restId': this.restId,
      'consent': false,
      'subscription':{}
    }
    this.restaurantService.sendConsentResponse(obj).then((data) => {
      console.log("dataObj", data);
      this.mobileNum = data['body']['data']['mobile'];
      this.emailId = data['body']['data']['email'];
      this.storageService.setItem('mobileNum', this.mobileNum);
      this.storageService.setItem('emailId', this.emailId);
      if (data['body']['type'] === 'success') {
        this.route.params.subscribe(params => {
          this.router.navigate(['/thankyou'])
        });
        this.storageService.clearStorage();
      }

    }).catch((err) => {
      console.log(err);
    });
  }

  verifyOtp() {
    console.log(this.otp);

    if (this.otp.status !== "INVALID") {
      this.spinnerShow = true;
      this.selectedPlan = this.subscriptionData.find(Item => Item.planName === this.planSelected);
      let obj = {
        'restId': this.restId,
        'mobile': this.storageService.getItem('mobileNum'),
        'otp': this.otp.value,
        'email': this.storageService.getItem('emailId'),
        'consent': true,
        'subscription':this.selectedPlan
      }
      this.restaurantService.verifyOtpDetails(obj).then((data) => {
        console.log("dataObj", data);
        this.response = data['body']['data'];
        this.otp.reset();
        if (data['body']['type'] === 'success') {
        this.modalService.dismissAll(reason =>{console.log(reason)});
          this.spinnerShow = true;
          this.route.params.subscribe(params => {
            this.router.navigate(['/thankyou']);
          })
          this.storageService.clearStorage();
        }
      }).catch((err) => {
        console.log(err);
        this.spinnerShow=false;
        this.otp.reset();
        this.modalService.dismissAll(reason =>{console.log(reason)});
      });
    } else {
      this.toasterService.presentToast('Please enter OTP', 'Error', 'error');
    }
  }

}
