import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { URL_KEYS, RequestUrlService } from './request-url.service';
import { TosterService } from './toster.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  constructor(private tosterService:TosterService,private httpClient: HttpClient) { }

  get(url: URL_KEYS, query = '', headers?) {
    return new Promise((resolve, reject) => {
      const reqUrlOptions = RequestUrlService.get(url);
      this.httpClient.get(reqUrlOptions.url + `${query}`, { observe: 'response', headers }).subscribe((response) => {
        resolve(this.handleResponse(response, reqUrlOptions));
      }, (error) => {
        console.log(error);
        reject(error);
      });
    });

  }
  post(url: URL_KEYS, body, headers?) {
    console.log(body);
    return new Promise((resolve, reject) => {
      const reqUrlOptions = RequestUrlService.get(url);
      console.log("hello",url);
      this.httpClient.post(reqUrlOptions.url, body, { observe: 'response', headers }).subscribe((response) => {
        console.log(response);
        
        resolve(this.handleResponse(response, reqUrlOptions));
      }, (error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  put(url: URL_KEYS, body, headers?) {
    return new Promise((resolve, reject) => {
      const reqUrlOptions = RequestUrlService.get(url);
      return this.httpClient.put(reqUrlOptions.url, body, { observe: 'response', headers }).subscribe((response) => {
        resolve(this.handleResponse(response, reqUrlOptions));
      }, (error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  async handleResponse(response, reqUrlOptions) {
    if (Math.floor(response['status'] / 100) === 2 && reqUrlOptions.showMsg) {
      await this.tosterService.presentToast(response['body']['message'], 'Success', 'success');
    }
    return response;
  }
}
