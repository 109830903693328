import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  static token = new BehaviorSubject(null);
  static baseUrl = environment.baseUrl;
  constructor(private storageService: StorageService) { 
    if(this.storageService.getItem('token') !==null){
      AppDataService.token.next(this.storageService.getItem('token'));
    }
    AppDataService.token.subscribe((token) => {
      this.storageService.setItem('token', token);
    })
  }
}
